import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Select from "../../components/Select/Select";
import {
  PUBLISHED_FROM_OPTIONS,
  PUBLISHED_TO_OPTIONS,
  SEARCH_FIELD_KEY,
} from "../../constants";

const PublishDateFilter = ({ refreshResults }) => {
  const [minDuration, setMinDuration] = useState("");
  const [maxDuration, setMaxDuration] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const minDurationFromSearch =
      searchParams.get(SEARCH_FIELD_KEY.MIN_DURATION) ??
      PUBLISHED_FROM_OPTIONS[0].value;

    const maxDurationFromSearch =
      searchParams.get(SEARCH_FIELD_KEY.MAX_DURATION) ?? null;

    setMinDuration(minDurationFromSearch);
    setMaxDuration(maxDurationFromSearch);
  }, [searchParams]);

  const onPublichedFromChange = (newValue) => {
    searchParams.set(SEARCH_FIELD_KEY.MIN_DURATION, newValue);
    setSearchParams(searchParams);
    refreshResults({ minDuration: newValue, maxDuration });
  };

  const onPublichedToChange = (newValue) => {
    if (newValue) {
      searchParams.set(SEARCH_FIELD_KEY.MAX_DURATION, newValue);
    } else {
      searchParams.delete(SEARCH_FIELD_KEY.MAX_DURATION);
    }
    setSearchParams(searchParams);
    refreshResults({ minDuration, maxDuration: newValue });
  };

  const { filteredPublishedFromOptions, filteredPublishedToOptions } =
    useMemo(() => {
      const filteredPublishedFromOptions = PUBLISHED_FROM_OPTIONS.filter(
        ({ value }) => (maxDuration ? Number(value) <= maxDuration : true)
      );
      const filteredPublishedToOptions = PUBLISHED_TO_OPTIONS.filter(
        ({ value }) => Number(value) >= minDuration
      );

      return { filteredPublishedFromOptions, filteredPublishedToOptions };
    }, [minDuration, maxDuration]);

  return (
    <>
      <div className="flex gap-x-2 gap-y-6 flex-col lg:flex-row -mb-8 md:mb-0">
        <Select
          label="Opublikowano od"
          options={filteredPublishedFromOptions}
          value={String(minDuration)}
          maxWidth={200}
          minWidth={200}
          onChange={onPublichedFromChange}
          isClearable={false}
          isSearchable={false}
        />
        <Select
          label="Opublikowano do"
          options={filteredPublishedToOptions}
          value={maxDuration && String(maxDuration)}
          maxWidth={200}
          minWidth={200}
          onChange={onPublichedToChange}
          isClearable={true}
          isSearchable={false}
        />
      </div>
    </>
  );
};

export default PublishDateFilter;
